<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
      class="py-4"
    >
      <div class="h3 mb-4 mr-auto">
        Склейка услуг
      </div>

      <div>
        <b-form-group label="Тип услуги">
          <b-form-radio-group
            v-model="selectedGluingTypeId"
            :options="gluningTypes"
            button-variant="outline-primary"
            size="sm"
            value-field="id"
            text-field="title"
            name="radio-btn-outline"
            buttons
          />
        </b-form-group>

        <div v-if="isPartnerService">
          <b-row>
            <b-col md="4">
              <b-form-group label="Выберите юр. лицо">
                <v-select
                  v-model="legalPartnerId"
                  class="legalPartnerSelect w-100 bg-white"
                  :reduce="legal => legal.id"
                  :options="legalCompanies"
                  placeholder="Выберите Юр.Лицо"
                  label="legalName"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Услуга склеена">
                <b-form-radio-group
                  v-model="isMerged"
                  :options="GLUNING_SERVICE_STATUSES"
                  button-variant="outline-primary"
                  size="sm"
                  value-field="value"
                  text-field="title"
                  name="radio-btn-outline"
                  buttons
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Возможность склеить">
                <b-form-radio-group
                  v-model="isMappable"
                  :options="GLUNING_SERVICE_MAPPABLES"
                  button-variant="outline-primary"
                  size="sm"
                  value-field="value"
                  text-field="title"
                  name="radio-btn-outline"
                  buttons
                />
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="partnersServicesSearched && !partnersServicesLoading">
            <template v-if="partnersServices.length">
              <b-table
                :fields="partnersServicesFields"
                :items="partnersServices"
                bordered
                hover
                responsive
                :small="true"
                class="bg-white crm-table w-100 tr-nowrap"
              >
                <template #cell(name)="row">
                  <div style="width: 160px">
                    {{ row.item.name }}
                  </div>
                </template>
                <!-- <template #cell(description)="row">
                  <div
                    style="width: 160px"
                    class="break-word"
                  >
                    {{ row.item.description }}
                  </div>
                </template> -->
                <template #cell(service)="row">
                  <div
                    v-if="row.item.service"
                    style="width: 160px"
                    class="break-word"
                  >
                    {{ row.item.service.name }}
                  </div>
                </template>
                <template #cell(partner)="row">
                  <div
                    v-if="row.item.partner"
                    class="break-word"
                  >
                    {{ row.item.partner.name }}
                  </div>
                </template>
                <template #cell(isActive)="row">
                  <b-badge
                    v-if="row.item.isActive"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Активирована
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Не активирована
                  </b-badge>
                </template>
                <template #cell(isMapable)="row">
                  <b-badge
                    v-if="row.item.isMapable"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Да
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Нет
                  </b-badge>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex">
                    <b-button
                      v-b-tooltip.hover.left
                      size="sm"
                      :title="row.item.isMapable ? 'Склеить' : 'Склейка недоступна'"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      :disabled="!row.item.isMapable"
                      @click="gluePartnerService(row.item)"
                    >
                      <b-icon icon="arrows-collapse" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.left
                      size="sm"
                      title="Отклеить"
                      :disabled="!row.item.service"
                      variant="danger"
                      :type="$const.PRIMARY_BUTTON"
                      class="ml-2"
                      @click="ungluePartnerService(row.item)"
                    >
                      <b-icon icon="arrows-expand" />
                    </b-button>
                  </div>
                </template>
              </b-table>

              <b-pagination
                v-model="partnerServicesCurrentPage"
                pills
                :total-rows="+partnersServicesTotalCount"
                :per-page="limit"
                style="padding-top: 16px"
                @input="fetchPartnerServices"
              />
            </template>

            <div
              v-else
              class="font-weight-bold"
            >
              Ничего не найдено
            </div>
          </template>

          <b-skeleton-table
            v-if="partnersServicesLoading"
            :rows="limit"
            :columns="partnersServicesFields.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>

        <div v-if="isMarketService">
          <div class="mt-2 mb-2 d-flex">
            <b-input-group>
              <b-form-input
                v-model="ourServicesFilters.name"
                size="sm"
                placeholder="По названию  услуги"
                @keyup.native.enter="clearFetchOurServices"
              />
              <b-form-input
                v-model="ourServicesFilters.code"
                size="sm"
                placeholder="По коду услуги"
                @keyup.native.enter="clearFetchOurServices"
              />
            </b-input-group>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-2"
              @click="clearFetchOurServices"
            >
              <b-icon icon="search" />
            </b-button>
          </div>
          <b-row class="mt-2 mb-4">
            <b-col md="4">
              <div class="mb-2">
                Юр. лица с которыми не связана услуга
              </div>

              <template v-if="showNotMergedLegalPartners">
                <div
                  v-for="(partner, index) in notMergedLegalPartners"
                  :key="`partner-${partner.id}-${index}`"
                  class="d-flex align-items-center mb-2"
                >
                  <div style="width: 350px">
                    <v-select
                      v-model="partner.id"
                      class="legalPartnerSelect w-100 bg-white"
                      :reduce="legal => legal.id"
                      :options="legalCompanies"
                      :clearable="false"
                      placeholder="Выберите Юр.Лицо"
                      label="legalName"
                      @input="onSelectNotMergedLegalPartner(partner.id)"
                    />
                  </div>

                  <b-button
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="ml-2"
                    @click="removeNotMergedLegalPartner(index)"
                  >
                    <b-icon
                      icon="x"
                    />
                  </b-button>
                </div>
              </template>

              <div class="d-flex">
                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  @click="addNotMergedLegalPartner"
                >
                  Добавить
                </b-button>

                <b-button
                  v-if="notMergedLegalPartners.length > 2"
                  size="sm"
                  :variant="showNotMergedLegalPartners ? 'outline-danger' : 'primary'"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-2"
                  @click="showNotMergedLegalPartners = !showNotMergedLegalPartners"
                >
                  {{ showNotMergedLegalPartners ? 'Скрыть список' : 'Раскрыть список' }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <template v-if="ourServicesSearched && !ourServicesLoading">
            <template v-if="ourServices.length">
              <b-table
                :fields="ourServicesFields"
                :items="ourServices"
                bordered
                hover
                responsive
                :small="true"
                class="bg-white crm-table w-100"
              >
                <template #cell(name)="row">
                  <div style="width: 250px">
                    {{ row.item.name }}
                  </div>
                </template>
                <template #cell(partnerServices)="row">
                  <div
                    v-for="(partnerService, index) in row.item.partnerServices"
                    :key="partnerService.id"
                    :class="{'mt-2': index}"
                  >
                    {{ partnerService.name }}
                  </div>
                </template>
                <template #cell(description)="row">
                  <div
                    style="width: 160px"
                    class="break-word"
                  >
                    {{ row.item.description }}
                  </div>
                </template>
                <template #cell(isActive)="row">
                  <b-badge
                    v-if="row.item.isActive"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Активирована
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Не активирована
                  </b-badge>
                </template>
                <template #cell(isMapable)="row">
                  <b-badge
                    v-if="row.item.isMapable"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Да
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Нет
                  </b-badge>
                </template>

                <template #cell(actions)="row">
                  <b-button
                    v-b-tooltip.hover.left
                    size="sm"
                    title="Склеить"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    @click="glueOurService(row.item)"
                  >
                    <b-icon icon="arrows-collapse" />
                  </b-button>
                </template>
              </b-table>

              <b-pagination
                v-model="ourServicesCurrentPage"
                pills
                :total-rows="+ourServicesTotalCount"
                :per-page="limit"
                style="padding-top: 16px"
                @input="fetchOurServices"
              />
            </template>

            <div
              v-else
              class="font-weight-bold"
            >
              Ничего не найдено
            </div>
          </template>

          <b-skeleton-table
            v-if="ourServicesLoading"
            :rows="limit"
            :columns="ourServicesFields.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showCustomMessage } from '@/helpers/messages';
import * as types from '@/store/types';

const GLUING_TYPES = {
  partnerService: {
    id: 0,
    title: 'Услуга партнера',
  },
  marketService: {
    id: 1,
    title: 'Услуга маркета',
  },
};

const GLUNING_SERVICE_STATUSES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

const GLUNING_SERVICE_MAPPABLES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

const NEED_LEGAL_PERSON_STATUSES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

export default {
  name: 'LaboratoryGluingService',

  data: () => ({
    GLUING_TYPES,
    GLUNING_SERVICE_STATUSES,
    GLUNING_SERVICE_MAPPABLES,
    NEED_LEGAL_PERSON_STATUSES,
    partnersServicesLoading: false,
    partnersServicesSearched: false,
    partnersServices: [],
    partnersServicesTotalCount: 0,
    partnerServicesCurrentPage: 1,
    partnersServicesFields: [
      {
        key: 'name',
        label: 'Название услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'code',
        label: 'Код услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'partner',
        label: 'Партнер',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'service',
        label: 'Привязанная к',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      // {
      //   key: 'description',
      //   label: 'Комментарий',
      //   sortable: true,
      //   tdClass: 'align-middle',
      //   class: 'text-center',
      // },
      {
        key: 'cost',
        label: 'Стоимость услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'isActive',
        label: 'Статус услуги',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'isMapable',
        label: 'Можно ли склеить',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'actions',
        label: '',
        tdClass: 'align-middle',
        class: ['actionsClass', 'text-center'],
      },
    ],

    ourServicesLoading: false,
    ourServicesSearched: false,
    ourServices: [],
    ourServicesTotalCount: 0,
    ourServicesCurrentPage: 1,
    ourServicesFields: [
      {
        key: 'name',
        label: 'Название услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'partnerServices',
        label: 'Склеена с',
        sortable: false,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'code',
        label: 'Код услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'isActive',
        label: 'Статус услуги',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'actions',
        label: '',
        tdClass: 'align-middle',
        class: ['actionsClass', 'text-center'],
      },
    ],
    ourServicesFilters: {
      code: null,
      name: null,
    },

    notMergedLegalPartners: [],
    showNotMergedLegalPartners: true,

    selectedGluingTypeId: null,
    isMerged: null,
    legalPartnerId: null,
    legalCompanies: null,
    legalCompaniesCount: null,
    needLegalPerson: null,

    limit: 15,

    isMappable: null,
  }),

  computed: {
    ...mapGetters({
      partnerServicesTrigger: types.PARTNERS_LABORATORY_SERVICES_TRIGGER,
      ourServicesTrigger: types.OUR_LABORATORY_SERVICES_TRIGGER,
    }),
    gluningTypes() {
      return Object.values(GLUING_TYPES);
    },
    isPartnerService() {
      return GLUING_TYPES.partnerService.id === this.selectedGluingTypeId;
    },
    isMarketService() {
      return GLUING_TYPES.marketService.id === this.selectedGluingTypeId;
    },
  },

  watch: {
    partnerServicesTrigger() {
      this.clearFetchPartnerServices();
    },
    ourServicesTrigger() {
      this.clearFetchOurServices();
    },
    legalPartnerId() {
      this.clearFetchPartnerServices();
    },
    isMerged() {
      this.clearFetchPartnerServices();
    },
    isMappable() {
      this.clearFetchPartnerServices();
    },
  },

  async created() {
    this.isLoading = true;
    try {
      await this.fetchLegalPartners();
      this.initStorageData();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    this.routeData();
  },

  methods: {
    routeData() {
      if (this.$route.query.id) {
        this.glueOurService({
          id: this.$route.query.id,
        });
      }
    },
    async fetchLegalPartners() {
      const { data: { data }, xTotalCount } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {
        limit: 500,
      });
      this.legalCompanies = data;
      this.allCount = xTotalCount;
    },
    clearFetchPartnerServices() {
      this.partnerServicesCurrentPage = 1;
      this.fetchPartnerServices();
    },
    async fetchPartnerServices() {
      if (this.partnersServicesLoading) return;
      if (!this.legalPartnerId) {
        showCustomMessage('warning', 'Ошибка валидации', 'Необходимо выбрать юр. лицо');

        return;
      }
      this.partnersServicesLoading = true;

      try {
        const { data: { data }, xTotalCount } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES,
          {
            limit: this.limit,
            offSet: (this.partnerServicesCurrentPage - 1) * this.limit,
            isMerged: this.isMerged,
            isMappable: this.isMappable,
            save: false,
            LegalPartnerId: this.legalPartnerId,
          });

        this.partnersServices = data;
        this.partnersServicesTotalCount = xTotalCount;
      } catch (e) {
        console.error(e);
      } finally {
        this.partnersServicesLoading = false;
        this.partnersServicesSearched = true;
      }
    },
    clearFetchOurServices() {
      this.ourServicesCurrentPage = 1;
      this.fetchOurServices();
    },
    async fetchOurServices() {
      if (this.ourServicesLoading) return;
      this.ourServicesLoading = true;

      try {
        const params = {
          limit: this.limit,
          offSet: (this.ourServicesCurrentPage - 1) * this.limit,
        };

        const { code, name } = this.ourServicesFilters;
        if (code) params.Code = code;
        if (name) params.Name = name;
        if (this.notMergedLegalPartners.length) {
          params.DoesNotMergedLegalPartnerIds = this.notMergedLegalPartners.map((item) => item.id).filter((item) => item !== null); // !== null если вдруг 0 :)
        }

        const { data: { data }, xTotalCount } = await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, params);
        this.ourServices = data;
        this.ourServicesTotalCount = xTotalCount;
      } catch (e) {
        console.error(e);
      } finally {
        this.ourServicesSearched = true;
        this.ourServicesLoading = false;
      }
    },
    gluePartnerService(item) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'GluePartnerServiceModal',
        props: { partnerServiceId: item.id },
      });
    },
    glueOurService(item) {
      if (this.selectedGluingTypeId) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'GlueOurServiceModal',
          props: {
            ourServiceId: item.id,
          },
        });
      } else {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'GluePartnerServiceModal',
          props: {
            partnerServiceId: item.id,
          },
        });
      }
    },
    ungluePartnerService(item) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Вы уверены что хотите отклеить услугу?',
            cb: async () => {
              await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_UNMAP, item.id);
              this.$store.commit(this.$types.TOGGLE_PARTNERS_LABORATORY_SERVICES_TRIGGER);
            },
          },
        },
      );
    },
    initStorageData() {
      const notMergedLegalPartners = localStorage.getItem('notMergedLegalPartners');
      if (Array.isArray(notMergedLegalPartners)) {
        this.notMergedLegalPartners = JSON.parse(notMergedLegalPartners);
      }
    },
    setNotMergedLegalPartners() {
      localStorage.setItem('notMergedLegalPartners', JSON.stringify(this.notMergedLegalPartners));
    },
    addNotMergedLegalPartner() {
      const partners = this.notMergedLegalPartners;
      if (partners.length && partners[partners.length - 1].id === null) {
        showCustomMessage('warning', 'Кажется у вас есть незаполненное поле', '');
        return;
      }
      partners.push({ id: null });
    },
    removeNotMergedLegalPartner(index) {
      this.notMergedLegalPartners.splice(index, 1);
      this.setNotMergedLegalPartners();
      this.clearFetchOurServices();
    },
    onSelectNotMergedLegalPartner() {
      this.setNotMergedLegalPartners();
      this.clearFetchOurServices();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tr-nowrap th {
  white-space: nowrap;
}
</style>
